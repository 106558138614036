import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { BACKEND_API } from "../../constants/backendApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { appVersion } from "../../version";

// Websocket
import socket from "../../websocket";

//Style
import "./NavbarComponent.css";

//Context
import { AuthContext } from "../../context/AuthContext";
import { authTypes } from "../../types/authTypes";

const NavbarComponent = ({ userData }) => {
  const { dispatch } = useContext(AuthContext);

  const navigate = useNavigate();

  const [message, setMessage] = useState("");

  const [allUsersList, setAllUsersList] = useState([]);

  const token = localStorage.getItem("token");

  const isAdminPreview = localStorage.getItem("adminPreview");

  const [userId, setUserId] = useState("");

  const [showNoti, setShowNoti] = useState(false);

  const [userInteracted, setUserInteracted] = useState(true);

  useEffect(() => {
    const handleUserInteraction = () => {
      setUserInteracted(true);
      document.removeEventListener("click", handleUserInteraction);
      document.removeEventListener("keydown", handleUserInteraction);
    };

    document.addEventListener("click", handleUserInteraction);
    document.addEventListener("keydown", handleUserInteraction);

    socket.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if (message.type === "WELCOME") {
        console.log("Welcome message:", message.message);
      } else if (message.type === "NEW_VERSION") {
        if (message.version.version !== appVersion) {
          console.log("New version available:", message.version.version);
          localStorage.setItem("show_noti", true);
          window.location.reload();
        }
      }
    };

    return () => {
      document.removeEventListener("click", handleUserInteraction);
      document.removeEventListener("keydown", handleUserInteraction);
      socket.close();
    };
  }, []);

  useEffect(() => {
    const showNoti = JSON.parse(localStorage.getItem("show_noti"));

    if (showNoti) {
      const notify = () => {
        toast(
          `La aplicación se ha actualizado correctamente a la versión: ${appVersion}.`,
          { autoClose: false }
        );

        if (userInteracted) {
          const audio = document.getElementById("notification-sound");
          if (audio) {
            audio.play().catch((error) => {
              console.error("Failed to play the audio:", error);
            });
          }
        }

        localStorage.setItem("show_noti", false);
      };

      setTimeout(() => {
        notify();
      }, 10000);
    }

    const currentHour = new Date().getHours();

    if (currentHour >= 5 && currentHour < 12) {
      setMessage("buenos dias");
    } else if (currentHour >= 12 && currentHour < 18) {
      setMessage("buenas tardes");
    } else {
      setMessage("buenas noches");
    }

    try {
      fetch(`${BACKEND_API}/api/users`, {
        method: "GET",
        credentials: "include",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.message === "Users") {
            const userNoAdmin = data.data.filter(
              (user) => user.profile !== "Admin" || user.profile !== "Gestor"
            );

            const usersNewArr = userNoAdmin.map((user) => {
              return { value: user._id, label: user.name };
            });
            setAllUsersList(usersNewArr);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, [token, userInteracted]);

  const handleLogout = () => {
    dispatch({ type: authTypes.logout });
    localStorage.removeItem("token");
    localStorage.removeItem("adminPreview");
    localStorage.removeItem("adminToken");
    localStorage.removeItem("userProfile");
    localStorage.removeItem("temp_doc");
    localStorage.removeItem("_grecaptcha");
  };

  const handleLoginAsUser = () => {
    if (userId && userId !== "") {
      try {
        const body = {
          idUser: userId,
        };

        fetch(`${BACKEND_API}/api/change-user`, {
          method: "POST",
          credentials: "include",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.message === "Usuario existe") {
              localStorage.setItem("adminToken", token);
              localStorage.setItem("adminPreview", true);
              localStorage.setItem("token", data.data);
              localStorage.setItem("userProfile", userData.profile);
              window.location.reload();
            }
          });
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleLogoutUser = () => {
    try {
      navigate("/admin/panel");
      const adminToken = localStorage.getItem("adminToken");
      localStorage.setItem("token", adminToken);
      localStorage.removeItem("adminPreview");
      localStorage.removeItem("adminToken");
      localStorage.removeItem("userProfile");
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  const handleChangeUser = (e) => {
    setUserId(e.value);
  };

  return (
    <>
      <ToastContainer />
      <nav className="navbar-container">
        <div
          className={`notification-container ${
            showNoti ? "show-noti" : "hide-noti"
          }`}
        >
          <ToastContainer
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
            position="top-right"
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </div>
        <div className="elms-container">
          <audio
            id="notification-sound"
            src="/assets/temp/noti-sound.mp3"
            preload="auto"
            style={{ display: "unset" }}
          ></audio>
          <div className="nb-greetings-container">
            <div className="profile-text-container">
              Hola {message}, {userData.name}
            </div>
          </div>
          {(userData.profile === "Admin" || userData.profile === "Gestor") && (
            <div className="nb-superadmin-menu">
              <Select
                options={allUsersList}
                onChange={handleChangeUser}
                placeholder="--Selecciona--"
              />
              <button
                className="nb-sa-btn"
                onClick={handleLoginAsUser}
                title="Acceder a Usuario"
              >
                Acceder Usuario
              </button>
            </div>
          )}
          {isAdminPreview && (
            <div className="nb-superadmin-menu">
              <button
                className="nb-sa-btn"
                onClick={handleLogoutUser}
                title="Cerrar Vista de Usuario"
              >
                Cerrar Vista de Usuario
              </button>
            </div>
          )}
          <div className="nb-menus-container">
            <div
              className="menu-container"
              onClick={() => setShowNoti(!showNoti)}
            >
              <img
                src="https://pagina-crediseguro.s3.amazonaws.com/portal-crediseguro/assets/icons/notification-bell-icon.webp"
                alt=""
                className="menu-icon"
              />
              <h1>Alertas</h1>
            </div>
            <div
              className="menu-container"
              onClick={() => navigate("/admin/metodos-de-pago")}
            >
              <img
                src="https://pagina-crediseguro.s3.amazonaws.com/portal-crediseguro/assets/icons/payment-methods-icon.webp"
                alt=""
                className="menu-icon"
              />
              <h1>Metodos de Pago</h1>
            </div>
            <div
              className="menu-container"
              onClick={() => navigate("/admin/perfil")}
            >
              <img
                src="https://pagina-crediseguro.s3.amazonaws.com/portal-crediseguro/assets/icons/profile-icon.webp"
                alt=""
                className="menu-icon"
              />
              <h1>Perfil</h1>
            </div>
            <div
              title="Cerrar sesión"
              className="menu-container line"
              onClick={handleLogout}
            >
              <h1>Cerrar Sesión</h1>
              <img
                src="https://pagina-crediseguro.s3.amazonaws.com/portal-crediseguro/assets/icons/logout-icon.webp"
                alt=""
                className="menu-icon"
              />
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default NavbarComponent;
